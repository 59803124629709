import { API } from 'src/config';
import { getKeys } from '../utils/APIUtil';
import {doGet, doPost}  from './FetchWrapper';

export const get = ( id = 0 ) => {
    return doGet(`/api/?event=screening.get&id=${id}`)
};

export const search = args => {
    return doGet(`/api/?event=screening.list${getKeys(args)}`)
};

export const searchWithDataByPatient = args => {
    return doGet(`/api/?event=screening.listWithDataByPatient${getKeys(args)}`);
}

export const modify = data => {
    return doPost(`/api/?event=screening.modify`, data)
};
export const create = data => {
    return doPost(`/api/?event=screening.create`, data)
};

export const remove = data => {
    return doPost(`/api/?event=screening.delete`, data)
};
