import { getKeys } from '../utils/APIUtil';
import { doGet, doPost } from './FetchWrapper';

export const get = (id = 0) => {
    return doGet(`/api/?event=user.get&id=${id}`);
};

export const getGroupAssignment = id => {
    return doGet(`/api/?event=user.getGroupAssignment&id=${id}`)
};

export const searchDoctors = (str="") => {
    return doGet(`/api/?event=user.list&roles=MED,MSP,ADM&str=${str}`)
};

export const searchOperators = (str="") => {
    return doGet(`/api/?event=user.list&roles=OPE&str=${str}`)
};

export const searchSocialOperators = (str="") => {
    return doGet(`/api/?event=user.list&roles=OSO&str=${str}`)
};

export const searchSocialCoordinators = (str="") => {
    return doGet(`/api/?event=user.list&roles=COO&str=${str}`)
};

export const search = args => {
    return doGet(`/api/?event=user.list${getKeys(args)}`)
};

export const modify = data => {
    return doPost(`/api/?event=user.modify`, data)
};

export const modifyInfo = data => {
    return doPost(`/api/?event=user.modifyInfo`, data)
};

export const modifyContacts = data => {
    return doPost(`/api/?event=user.modifyContacts`, data)
};

export const modifyAccount = data => {
    return doPost(`/api/?event=user.modifyAccount`, data)
};

export const modifyPwd = data => {
    return doPost(`/api/?event=user.modifyPwd`, data)
};

export const create = data => {
    return doPost(`/api/?event=user.create`, data)
};

export const remove = data => {
    return doPost(`/api/?event=user.delete`, data)
};

