import { getKeys,  } from '../utils/APIUtil';
import {doGet, doPost}  from './FetchWrapper';

export const search = args => {
    return doGet(`/api/?event=patient.list${getKeys(args)}`)
};

export const listMedicalData = args => {
    return doGet(`/api/?event=patient.listMedicalData${getKeys(args)}`)
};

export const get = (id = 0) => {
    return doGet(`/api/?event=patient.get&id=${id}`)
}

export const getGroupAssignment = id => {
    return doGet(`/api/?event=patient.getGroupAssignment&id=${id}`)
}

export const getGroups = id => {
    return doGet(`/api/?event=patient.getGroups&id=${id}`)
}

export const create = values => {
    return doPost(`/api/?event=patient.create`, values)
};

export const modify = values => {
    return doPost(`/api/?event=patient.modify`, values)
};

export const modifyContacts= data => {
    return doPost(`/api/?event=patient.modifyContacts`, data)
};

export const modifyAccount= data => {
    return doPost(`/api/?event=patient.modifyAccount`, data)
};

export const remove = ids => {
    return doPost(`/api/?event=patient.delete`, ids)
}


