import { PieChart as PieChartIcon, User as UserIcon, User as UsersIcon } from 'react-feather';
import {ROLES, paths, VIEWACCESS} from "../../../constants";


const sections = [
    {
        subheader: 'Reports',
        allowedSections: [VIEWACCESS.admin.management, VIEWACCESS.fall.management],
        items: [
            {
                title: 'Dashboard',
                icon: PieChartIcon,
                allowedSection: VIEWACCESS.admin.management,
                href: paths.dashboard
            },
            {
                title: 'Chiamate pazienti',
                icon: PieChartIcon,
                allowedSection: VIEWACCESS.fall.management,
                href: paths.operatorDashboard
            },
            {
                title: 'Gestione cadute',
                allowedSection: VIEWACCESS.fall.management,
                icon: PieChartIcon,
                href: paths.assistantDashboard
            }
        ]
    },

    {
        subheader: 'Richieste paziente',
        allowedSections: [VIEWACCESS.patientRequest.management, VIEWACCESS.ownPatientRequest.management],
        items: [
            {
                title: 'Tutte le richieste',
                icon: PieChartIcon,
                allowedSection: VIEWACCESS.patientRequest.management,
                href: '/app/requests'
            },
            {
                title: 'Attività assegnate a me',
                icon: PieChartIcon,
                allowedSection: VIEWACCESS.ownPatientRequest.management,
                href: '/app/assigned/requests'
            },
            {
                title: 'Calendario delle mie attività',
                allowedSection: VIEWACCESS.ownPatientRequest.management,
                icon: PieChartIcon,
                href: '/app/requests/calendar'
            },
            {
                title: 'Organizzazione percorsi',
                allowedSection: VIEWACCESS.patientRequest.management,
                icon: PieChartIcon,
                href: '/app/admin/requests-stops' //TODO: better url
            }
        ]
    },

    {
        subheader: 'Anagrafiche',
        allowedSections: [VIEWACCESS.patient.visualization, VIEWACCESS.patient.management, VIEWACCESS.exam.visualization, VIEWACCESS.exam.management ],
        items: [

            {
                title: 'Pazienti',
                icon: UsersIcon,
                href: '/app/management/patients',
                items: [
                    {
                        allowedSection: VIEWACCESS.patient.visualization,
                        title: 'Elenco pazienti',
                        href: '/app/management/patients'
                    },
                    {
                        allowedSection: VIEWACCESS.patient.management,
                        title: 'Nuovo paziente',
                        href: '/app/management/patients/create'
                    }
                ]
            },


            {
                title: 'Visite',
                icon: UsersIcon,
                href: '/app/management/exams',
                items: [
                    {
                        allowedSection: VIEWACCESS.exam.visualization,
                        title: 'Elenco visite',
                        href: '/app/management/exams'
                    },
                    {
                        allowedSection: VIEWACCESS.exam.management,
                        title: 'Nuova visita',
                        href: '/app/management/exams/create'
                    }
                ]
            },
            {
                title: 'HUFI',
                icon: UsersIcon,
                href: '/app/management/hufi',
                items: [
                    {
                        allowedSections: [VIEWACCESS.exam.visualization, VIEWACCESS.hufi.visualization],
                        title: 'Elenco Hufi',
                        href: '/app/management/hufi'
                    },
                    {
                        allowedSections: [VIEWACCESS.exam.management, VIEWACCESS.hufi.management],
                        title: 'Nuovo Hufi',
                        href: '/app/management/hufi/create'
                    }
                ]
            },
        ]
    },
    {
        subheader: 'Amministrazione',

        allowedSection: VIEWACCESS.admin.management,
        items: [
            {
                title: 'Gruppi',
                icon: UserIcon,
                href: '/app/admin/groups',
                items: [
                    {
                        title: 'Elenco gruppi',
                        href: '/app/admin/groups'
                    },
                    {
                        title: 'Nuovo gruppo',
                        href: '/app/admin/groups/create'
                    }
                ]
            },
            {
                title: 'Utenti',
                icon: UsersIcon,
                href: '/app/admin/users',
                items: [
                    {
                        title: 'Elenco utenti',
                        href: '/app/admin/users'
                    },
                    {
                        title: 'Nuovo utente',
                        href: '/app/admin/users/create'
                    },
                    {
                        title: 'Specializzazioni',
                        href: '/app/admin/specs',
                        items: [
                            {
                                title: 'Elenco specializzazioni',
                                href: '/app/admin/specs'
                            },
                            {
                                title: 'Nuova specializzazione',
                                href: '/app/admin/specs/create'
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Domande',
                icon: UsersIcon,
                href: '/app/admin/questions',
                items: [
                    {
                        title: 'Elenco domande',
                        href: '/app/admin/questions'
                    },
                    {
                        title: 'Nuova domanda',
                        href: '/app/admin/questions/create'
                    },
                    {
                        title: 'Questionari',
                        href: '/app/admin/categories',
                        items: [
                            {
                                title: 'Elenco questionari',
                                href: '/app/admin/categories'
                            },
                            {
                                title: 'Nuovo questionario',
                                href: '/app/admin/categories/create'
                            },
                            {
                                title: 'Manutenzione',
                                href: '/app/admin/questions/management'
                            }
                        ]
                    },
                    {
                        title: 'Opzioni',
                        href: '/app/admin/options',
                        items: [
                            {
                                title: 'Elenco opzioni',
                                href: '/app/admin/options'
                            },
                            {
                                title: 'Nuova opzione',
                                href: '/app/admin/options/create'
                            }
                        ]
                    },
                    {
                        title: 'Scale di rischio',
                        href: '/app/admin/risk-scales',
                        items: [
                            {
                                title: 'Elenco scale di rischio',
                                href: '/app/admin/risk-scales'
                            },
                            {
                                title: 'Nuova scala di rischio',
                                href: '/app/admin/risk-scales/create'
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Servizi',
                icon: UsersIcon,
                href: '/app/admin/service',
                items: [
                    {
                        title: 'Elenco servizi',
                        href: '/app/admin/service'
                    },
                    {
                        title: 'Nuovo servizio',
                        href: '/app/admin/service/create'
                    }
                ]
            },
            {
                title: 'Dati medici',
                icon: UsersIcon,
                href: '/app/admin/medical-data',
                items: [
                    {
                        title: 'Elenco dati medici',
                        href: '/app/admin/medical-data'
                    },
                    {
                        title: 'Nuovo dato medico',
                        href: '/app/admin/medical-data/create'
                    },
                    {
                        title: 'Unità',
                        href: '/app/admin/units',
                        items: [
                            {
                                title: 'Elenco unità',
                                href: '/app/admin/units'
                            },
                            {
                                title: 'Nuova unità',
                                href: '/app/admin/units/create'
                            }

                        ]
                    }
                ]
            },
            {
                title: 'Eventi',
                icon: UsersIcon,
                href: '/app/admin/screenings',
                items: [
                    {
                        title: 'Elenco eventi',
                        href: '/app/admin/screenings'
                    },
                    {
                        title: 'Nuovo evento',
                        href: '/app/admin/screenings/create'
                    },
                    {
                        title: 'Calendario eventi',
                        href: '/app/admin/screenings-calendar'
                    }

                ]
            },
            {
                title: 'Alert',
                icon: UsersIcon,
                href: '/app/admin/alerts',
                items: [
                    {
                        title: 'Elenco alert',
                        href: '/app/admin/alerts'
                    },
                    {
                        title: 'Nuovo alert',
                        href: '/app/admin/alerts/create'
                    },
                    {
                        title: 'Lista degli upload',
                        href: '/app/admin/sent-alerts'
                    }
                ]
            },
            {
                title: 'Log',
                href: '/app/admin/logs',
                icon: UserIcon
            },
            {
                title: 'Inserimento manuale dati',
                href: '/app/admin/manual-insert',
                icon: UserIcon
            }
        ]
    },
    {
        subheader: 'Accounts',
        items: [
            {
                title: 'Il mio account',
                href: '/app/account',
                icon: UserIcon
            }
        ]
    }

];

export default sections;
